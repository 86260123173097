.card-image{
    height: 250px;
    overflow: hidden;
    vertical-align:middle;


}
.card-image img{
    overflow: hidden;
    vertical-align: middle;
    
   
}
.btn-floating.halfway-fab {
    position: absolute;
    right: 24px;
    bottom: 65px;
}
.money{
	font-family: monospace;
	/*font-size: .1rem;*/
	font-weight: 200;
}
.card .card-image .responsive-img {
    display: block;
    border-radius: 2px 2px 0 0;
    position: relative;
    left: 0;
    right: 0;
     top: -40px!important;
    bottom: 220px!important;

     height: 150%!important;
    overflow: hidden!important;
    vertical-align: middle!important;
}
@media (max-width: 600px) {

    .card .card-image .responsive-img {
        display: block;
        border-radius: 2px 2px 0 0;
        position: relative;
        /* left: 100px; */
        /* right: 100px; */
        top: -150px!important;
        bottom: 0px!important;

        height: auto!important;
        overflow: hidden!important;

    }
}
.sold-out{
    position: absolute;
    z-index: 2;
}
.sold-out-hide{
   display: none;
}


