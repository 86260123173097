.alert-info{
	z-index: 9999999;
}
.basket-container{

	
	position: fixed;
    top: 12vh;
	right: 0%;
	z-index: 99999;
    width: 27vw;
}
.cart-product{

	height:60px;
}

.product-name{
	position:relative;
	top: -40px;
    left: 25px;
}
.btn-small {
    color: #f44336;
    padding: 2px;
	margin-top: 2px;
	line-height: 20px;
	background-color: transparent;
}

 span button{
	border:0px;
	background-color: inherit;
}
.add-button{
	border:0px;
	background-color: inherit;
}
.product-quantity {
    position: relative;
    top: -5px;
    left: -85px;
}
.product-price{
    position: relative;
    top: -20px;
    left: -20px;
}
img{
    width:50px;
}
td, th {
    padding:0px 0px;
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    border-radius: 2px;

}
/*.basket-image{*/

/*  */
/*    width: 100%!important;*/
/*     height: 13%!important; */
/*    overflow: hidden!important;*/
/*    vertical-align: middle!important;*/
/* */
/*}*/
.image-table-td{
    width:23%;
}

.basket-container .white{
    line-height: 30px;
}
.basket-container .card{
    margin: 0px;
    border-radius: 3px;
}
.card-padding{
    padding-top: 2%;
    padding-right: 5%;
    padding-bottom: 5%;
    padding-left: 5%;
    font-size: medium;
}
.shadow-icon{
        background-color: #bfbfcc;
    border-radius: 30px;
}
.mat-icon{
        top: 5px;
    position: relative;
}
.no-text-overflow{
    white-space: nowrap; 
    width: 12vw; 
    overflow: hidden;
    text-overflow: clip;
    font-size: 1.2vw;
    padding-bottom:.8vw ;
    /* border: 1px solid #000000; */
}
.cart-quantity-name{
    text-align: center;
    font-weight: 400;
    font-size:1.1vw;

   
}
.item-qty{
    padding-right: 8%;
    padding-left: 8%;
}
.basket-container table, th, td {
    border: none;
    /*padding-bottom: 10px!important;*/
}
.additional-info-cart{
    font-size: 1rem;
}
.additional-info-cart .addition-data{
    padding: 0px!important;
    line-height: 25px;
}
.addition-data-right{
    text-align: right;
}
.basket-header{
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
}

.image-table-td {

    overflow: hidden;

}
.image-table-td img{
    transition: transform .5s ease;
    transform: scale(1.5);
}
/*tr.table-row-tr>td {*/
/*    padding-bottom: 1.2em;*/
/*    padding-left: 10em;*/
/*}*/
div.overflow{
    overflow-y: auto;
    max-height: 60vh;
    margin-right: -50px;
    padding-right: 50px;
}
@media (max-width: 600px) {
.responsive-basket{

    display:none;
}
}

@media (min-width: 600px) {
    .show-on-small{

        display:none;
    }
}
.show-on-small{
    position: fixed;
    z-index: 999;
    width: 100%;
    /*height:10%;*/
    bottom: 0%;
}
.card-padding .btn {

    width: 100%;
}
.disable-basket{
    display: none;
}
.enable-basket{
    display: block;
}
.item-small-qty {
    margin: 10px;
    border: 1px solid tomato;
}
.show-on-small table{
    margin-top: 2%;
    margin-bottom: 2%;
}
.show-on-small table tr{
    color: whitesmoke;
}
.close-button{
    margin-left:70%;
}
.bag-name{
    color:#000000;
}
.empty-cart-image{
    width:40%;
    position: relative;

    left: 80px;
}
.empty-cart-image-container{
    padding-left: 4%;
    padding-top: 2%;
}
.inside-bag img{
    width:3vw;
}



   /*small button*/



/*.custom-small-button{*/
/*    border: 1px solid black;*/
/*    height: 5vh;*/
/*    width: 10vw;*/
/*    border-width: thin;*/
/*    border-radius:7px;*/
/*    box-sizing: border-box;*/
/*    border-color: rgb(197, 190, 190);*/

/*}*/
/*.custom-small-button:hover{*/
/*    border: 1px solid black;*/
/*    height: 5vh;*/
/*    width: 10vw;*/
/*    border-width: thin;*/
/*    border-radius:7px;*/
/*    box-sizing: border-box;*/
/*    border-color: rgb(152, 152, 152);*/
/*    background-color: #e8eaf6;*/
/*}*/


.custom-small-button-text{
    position: absolute;
    padding-left: 10px;
    padding-top: 2px;
    color: #949392;
    font-weight: 500;
}


.small-button-image img{
    width: 2.3vw;
}

.basket-heading{
    font-size: 1.4vw;
    font-weight: 600;
    padding-left: 5vw;
    padding-right:6vw;
}
.hide-empty-cart{
    display: none;
}
.show-empty-cart{
    display: block;
}
/*.small-button-image-container img{*/
/*   width: 5%;*/
/*}*/



/*.basket-head{*/
/*    padding-top: .7vw;*/
/*}*/

/*.bag-main-head{*/
/*    font-size:1.8vw;*/
/*    padding-left: 5vw;*/

/*    padding-right: 6vw;*/
/*    position: relative;*/

/*    bottom: 1.3vw;*/
/*}*/

/*.small-button-image{*/
/*    position: relative;*/
/*    bottom: .4vw;*/
/*}*/
.button-image-container img{
    width :1.6vw;
}
.basket-head{
    padding-top: .4vw;
}