.table-space{
    padding:5px;
}
.container-small-payment{
    width: auto;
    background-color: #FFFFFF;
    /*margin: 0 auto;*/
    /*margin-left: 15%;*/
    border-radius:2%;
}
.container-small-inside{
    padding:3%;
}
.space-big-pad4{
    padding:4%;
}
.space-big-pad4-top{
    padding-top: 4%;
}
.payment-inside-container{
    padding:5%;
}
.container-small-payment-bill{
    width: 70%;
    background-color: #FFFFFF;
    /*margin: 0 auto;*/
    margin-left: 15%;
    border-radius: 2%;
}
.width{
    width:60%;
    height: 5%;
}
.space-between{
    background-color: #e8eaf6;
    height:100px;
}
.memo-category{
    /*border-top: 1px solid #e4e4e4;*/
    font-size: 1rem;
    font-weight: 600;
}
hr {
    /* -webkit-box-sizing: content-box; */
    /* box-sizing: content-box; */
    /* height: 0; */
    border-top: .2px solid #e4e4e4;
}
.memo-food{
    font-size: 1rem;
}
.table-space {
    padding: 5px;
    text-align: left;
    /*width: 100%;*/
}
.table-space-name{
    padding: 5px;
    text-align: left;
    width:36%;
    padding-left: 2%;
}
.memo-bottom-table {
    /*position: relative;*/
    /*!* right: 1px; *!*/
    /*left: 88%;*/
    text-align: right;
}
.table-space-price{
    padding: 5px;
    text-align: left;
    /*width:15%;*/
}
.order-title {
    padding-top: 10px;
    padding-bottom: 15px;
    font-weight: 700;
}
.memo-space{

}
.custom-wide-button{
    border: 1px solid black;
    height: 35px;
    width: 100%;
    border-width: thin;
    border-radius:7px;
    box-sizing: border-box;
    border-color: rgb(197, 190, 190);

}
.custom-wide-button:hover{
    border: 1px solid black;
    height: 35px;
    width: 100%;
    border-width: thin;
    border-radius:7px;
    box-sizing: border-box;
    border-color: rgb(152, 152, 152);
    background-color: #e8eaf6;
}
/*img{*/
/*width:4%;*/
/*}*/
.button-image-container{
    padding-left: 10px;
}
.button-image-container-tick {

    position: relative;
    width: 60%;
    left: 370px;
    bottom: 3px;
}
.button-image{
        width: 7%;
}
.h-purple{
    color:#8190ef;
}
.button-image-tick{
    width:6%;
}
.custom-button-text{
position: absolute;
    padding-left: 10px;
    padding-top: 2px;
    color: #949392;
    font-weight: 500;
}
.space-medium{
    padding:1.2%;
}
.space-pad-2{
    padding:2%;
}
.font-weight-med{
    font-weight: inherit;
    font-size: 1.1rem;
}