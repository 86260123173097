.autocomplete-content {
    position: absolute;
}
.suggest-row{
    width:100%;
    cursor:pointer;
    list-style-type: none;
    /*padding: 1%;*/
    /*border: 1px solid black;*/
    padding: 8px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;
}

.suggest-row-small{
    width:100%;
    cursor:pointer;
    list-style-type: none;
    /*padding: 1%;*/
    /*border: 1px solid black;*/
    padding: 8px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;
}
.suggest-row:hover{
    width:100%;
    background-color: #e9e9e9;
    padding: 8px;
    border-bottom: 1px solid #d4d4d4;
}
.suggest-row-small:hover{
    width:100%;
    background-color: #e9e9e9;
    padding: 8px;
    border-bottom: 1px solid #d4d4d4;
}
.suggestion-show{
    display: block;

    position: relative;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    /*top: 100%;*/
    /*left: 0;*/
    /*right: 0;*/
    /*width:35%;*/
    background-color: white;

}

.suggestion-show-small {
    display: block;
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    /* top: 100%; */
    /* left: 0; */
    /* right: 0; */
    width: 11%;
    background-color: white;
}

.suggestion-hide{
    display: none;
}
.suggestion-hide-small{
    display: none;
}
.place-image-container {
    position: relative;
    padding: 2%;
}
.place-image-container img{
    width:4%;
}
.place-suggestion-text{
    padding: 2%;
}
.width50{
    width:50%;
}
.padding5{
    padding: 5%;
}
.right-pad-20{
    padding-right: 10%;
}
.suggestion{
    display:inherit;
}
.no-suggestion{
    display: none;
}
input{
    height:50%
}
.hide-address-name{
    display:none;
}
.width{
    max-width: 30%;
}
.show-address-name{

}

