#heading-2-id{
	font-family: 'roboto';
	font-size: 1rem;
}
.asd{
	font-size: 1rem;
}
.heading-2{
	color: black;
}
#heading-2-id .table-of-contents{
    margin-top: 1.5%;
}
#heading-2-id .table-of-contents a {
    display: inline-block;
    font-weight: 300;
    color: #757575;
    padding-left: 20px;
    height: 1.5rem;
    line-height: 1.5rem;
    letter-spacing: .4;
    display: inline-block;
}

#heading-2-id .table-of-contents a.active {
    font-weight: 500;
    padding-left: 18px;
    /*border-left: 2px solid #ee6e73;*/
}
#heading-2-id .table-of-contents a {
    display: inline-block;
    font-weight: 300;
    color: #757575;
    padding-left: 20px;
    height: 1.5rem;
    line-height: 1.5rem;
    letter-spacing: .4;
    display: inline-block;
}
.date-time{
    padding-top: 5%;

}

.main-details-content h4 {
    font-family: 'PT Sans', sans-serif;
    margin-top: .5rem;
    margin-bottom:.5rem;

}
.price{
    margin-top:2px;
}

#heading-2-id .table-of-contents a.active {
    font-weight: 500;
    padding-right: 18px;
    padding-left: 0px;
    /* border-left: 2px solid #ee6e73; */
    
}
#heading-2-id .table-of-contents a {
    font-weight: 500;
    padding-right: 18px;
    padding-left: 0px;
    
}
.horizontal .card-image img {
    display: block;
    border-radius: 2px 2px 0 0;
    position: initial;
    /*left: 100px;*/
    /*right: 100px;*/
    /*top: -150px !important;*/
    /*bottom: 0px !important;*/
    /*height: auto !important;*/
    /*overflow: hidden !important;*/
}

@media (max-width: 600px) {
    .main-image-container {
         position: static;
        width: 100%!important;
        /* top: 10%; */
        border-radius: 25px;
        z-index:100;
    }
    .main-image-container img {
        width: 100%;
        /* height: 40%; */
    }
    .pinned-top {
        top: 0px!important;
        position: fixed;
        /* left: 100px; */
    }
    /*.details-header {*/
    /*    position: relative;*/
    /*}*/
    .details-header {
        height: 9%;
         /*border: 1px solid rgb(224, 197, 197);*/
        z-index: 100;
    }
    .button-text {
        margin: 4%;
    }
    .back-button {
        margin-top: 4%;
    }
    .date-time {
        padding-top: 7%;
    }
}
@media only screen and (max-width: 992px){

    .main-image-container {
        position: static;
        width: 100%!important;
        /* top: 10%; */
        border-radius: 25px;
        z-index:100;
    }
    .main-image-container img {
        width: 100%;
        /* height: 40%; */
    }
    .pinned-top {
        top: 0px!important;
        position: fixed;
        /* left: 100px; */
    }
    /*.details-header {*/
    /*    position: relative;*/
    /*}*/
    .details-header {
        height: 9%;
        /*border: 1px solid rgb(224, 197, 197);*/
        z-index: 100;
    }
    .button-text {
        margin: 4%;
    }
    .back-button {
        margin-top: 4%;
    }
    .date-time {
        padding-top: 7%;
    }
}