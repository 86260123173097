.profile-image img{
    width:5%;
    float: left;
    /*margin-top:1%;*/
}
.comment-content{
    /*margin-left:7%;*/
}
.reply{
    margin-left:60%;
}
.reply-comment{
    width:40%;
    margin-left:10%;
}
.custom-textarea{
    padding:0px!important;
}

.reaction{
    display:flex;
}
.dislike{
    margin-left:2%;
}
.reply-comment{
    display:flex;
}
.reply-button{
    margin-top:7%;
    margin-left:7%;
}
.details-section{
    margin-top:2%;
}
.ingredients-section{
    margin-top:5%;
}
.comments-section{
    margin-top:5%;
}
.name{
    font-size: 1.2rem;
    font-weight: 500;
}
.comment-space{
    padding:1%;
}
.comment-username{
    margin-left: 1%;
}
.content{
    margin-left: 5%;
}