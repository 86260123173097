.top-cart-icon{

    width: 3.9vw;

    /*position: relative;*/
    /*top: 6px;*/
    /*left: 10px;*/

}
.item-count{
    color: green;
    position: relative;
    /* top: 10px; */
    right: 2vw;
    bottom: 1.7vw;
    background-color: #fbfbfb;
    /*border-radius: 25px;*/
}