.container1 {

    width: 35%;
    margin: 0 auto;

}
.fb-blue{
    background-color:#3b5998;
}
.character-counter{
    display:none;
}
.container-small-login{
    width:80%;
    margin:0 auto;
}
.facebook-login-button{
    width: 100%;
    margin-top: 2%;

    margin-bottom: 4%;
}
.login-mobile-input{
    width: 68%!important;
}
.mobile-input-button{
    width:29%;
}
.container-small-of-login {

    width: 80%;
    background-color: #FFFFFF;
    margin: 0 auto;

}
.full-basket-container-login{


    width: 100%;
    height: 508px;
    position: relative;
    background-color:#e8eaf6;
    padding-top: 4%;


}
.text-one-rem{
    font-size: 1rem;
}
.font-weight-600{
    font-weight: 600;
}
.google-red{
    background-color: #DB4437;
}
.login-heading{
    font-weight: 600;
    font-size: larger;
}
.space-big{
    padding: 2%;
}
.kep-login-facebook {

    width: 100%;

}
.kep-login-facebook {

    padding: calc(.24435vw + 2.38843px) calc(.34435vw + 15.38843px)!important;
}


.google-login-button{
    background-color: rgb(255, 98, 98)!important;
    display: inline-flex;
    align-items: center;
    color: #eee!important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px!important;
    padding: 0px!important;;
    border-radius: 2px!important;;
    border: 1px solid transparent;
    font-size: 14px!important;;
    font-weight: 800!important;;
    font-family: Roboto, sans-serif!important;;
    width: 100%!important;;
}
google-login-button:after{
    content: " ";
    padding-left: 10px;
}

@media (max-width: 600px) {
    .mobile-input-button {
        width: 100%;
    }
    .container1 {
        width: 50%;
        margin: 0 auto;
    }
    .login-mobile-input {
        width: 100% !important;
    }



    .suggestion-show-small {

        width: 43%;
    }


}