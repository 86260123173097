.cart-item-number{
    position: fixed;
    top:15px;
    right:120px;
}
/* #cover-video{
    width:100%;
}
.table-of-contents li {
     padding: 0!important;
} */

.table-of-contents li {
    padding: 0!important;
}