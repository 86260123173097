.alert-info{
    z-index: 9999999;
}

.container-small{
    width: 80%;
    background-color: #FFFFFF;
    margin: 0 auto;
}
.full-basket-container{


    width: 100%;
    height: 100%;
    position: relative;
    background-color:#e8eaf6;
    padding-top: 4%;
    padding-bottom: 5%;


}

.cart-product{

    height:60px;
}

.product-name{
    position:relative;
    top: -40px;
    left: 25px;
}
.btn-small {
    color: #f44336;
    padding: 2px;
    margin-top: 2px;
    line-height: 20px;
    background-color: transparent;
}

span button{
    border:0px;
    background-color: inherit;
}
.add-button{
    border:0px;
    background-color: inherit;
}
.product-quantity {
    position: relative;
    top: -5px;
    left: -85px;
}
.product-price{
    position: relative;
    top: -20px;
    left: -20px;
}
img{
    width:50px;
}
td, th {
    padding:0px 0px;
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    border-radius: 2px;

}
/*.basket-image{*/

/*  */
/*    width: 100%!important;*/
/*     height: 13%!important; */
/*    overflow: hidden!important;*/
/*    vertical-align: middle!important;*/
/* */
/*}*/
.image-table-td{
    width:23%;
}
.basket-container .white{
    line-height: 30px;
}
.basket-container .card{
    margin: 0px;
    border-radius: 3px;
}
.card-padding{
    padding-top: 2%;
    padding-right: 5%;
    padding-bottom: 5%;
    padding-left: 5%;
    font-size: medium;
}
.shadow-icon{
    background-color: #bfbfcc;
    border-radius: 30px;
}
.mat-icon{
    top: 5px;
    position: relative;
}
.cart-quantity-name{
    text-align: center;
}
.item-qty{
    padding-right: 8%;
    padding-left: 8%;
}
.basket-container table, th, td {
    border: none;
    /*padding-bottom: 10px!important;*/
}
.additional-info-cart .addition-data{
    padding: 0px!important;
    line-height: 25px;
}
.addition-data-right{
    text-align: right;
}
.basket-header{
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
}

.image-table-td {

    overflow: hidden;

}
.image-table-td img{
    transition: transform .5s ease;
    transform: scale(1.5);
}
/*tr.table-row-tr>td {*/
/*    padding-bottom: 1.2em;*/
/*    padding-left: 1em;*/
/*}*/
/*select {*/
/*    display: block;*/
/*}*/
.order-large-button{
    width: 100%;
}
.deep-font{

    font-weight: 500;
}
.text-allign-right{
text-align: right;
}

/*.seperate-order{*/

/*    margin-top: 3%;*/
/*    margin-bottom: 2%;*/

/*}*/
[type="radio"]:not(:checked), [type="radio"]:checked {
    position: relative;
    opacity: 999;
    pointer-events: none;
}
.pointview{
    display:inherit;
}
.nopointview{
    display:none;
}
.addresstview{
    display:inherit;
}
.noaddressview{
    display:none;
}


select option {
    margin: 40px;
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

select option {
    margin: 40px;
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

select option[value="1"] {
    background: rgba(100, 100, 100, 0.3);
}

select option[value="2"] {
    background: rgba(150, 150, 150, 0.3);
}

select option[value="3"] {
    background: rgba(200, 200, 200, 0.3);
}

select option[value="4"] {
    background: rgba(250, 250, 250, 0.3);
}
.space-custom-fullbasket{

    width: 100%;
    height: 2%;
    padding-top: 2%
}

.margin-bottom{
    margin-bottom: 2%;
}

.back-image img{
    width:80%;
    padding-top: 15%;
}

