*{
    margin: 0;
    padding: 0;
}
.rate {
    float: left;
    height: 16px;
    padding: 0 10px;
}
.rate:not(:checked) > input {
    position:absolute;
    top:-9999px;
}
.rate:not(:checked) > label {
    float:right;
    width:1em;
    overflow:hidden;
    white-space:nowrap;
    cursor:pointer;
    font-size:20px;
    color:#ccc;
}
.rate:not(:checked) > label:before {
    content: '★ ';
}
.rate > input:checked ~ label {
    color: #ffc700;
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
    color: #deb217;
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
    color: #c59b08;
}

[type="radio"]:checked+label:after, [type="radio"].with-gap:checked+label:after {
    background-color: #26a69a;
    display: contents!important;
}
[type="radio"]:not(:checked)+label:before, [type="radio"]:not(:checked)+label:after {
    border: none!important;
}
[type="radio"]+label:before, [type="radio"]+label:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
     margin: 0px!important;
    width: 16px;
    height: 16px;
    z-index: 0;
    -webkit-transition: .28s ease;
    transition: .28s ease;
}
.half-width{
    width:40%;
}

[type="radio"]:checked+label:before {
    border: 0px solid transparent;
}