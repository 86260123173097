.add-remove-icon img{
    width:1.2vw;
}
.product-space{
    padding-bottom: .4vw;
    padding-top: .4vw;
}
.category-top-speace{
    padding-top: .6vw;
}

.add-remove-icon{
    cursor:pointer;
}