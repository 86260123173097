.alert-outer{
    display: block;
    position: fixed;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 9999;
    /* top: 100%; */
    /* left: 0; */
    /* right: 0; */
    width: 100%;
    background-color: #673ab7;
    height:10vh;

}

.alert-text{
    margin:30vw;
    color: #ffffff;
    font-size: 1.3rem;
    font-family:Meera;
}
.alert-image img{
    width:2.4vw;
}
.alert-image{
    position: relative;
    left: 14vw;
    top: .7vw;

}

.hide-alert{
    display: none;
}