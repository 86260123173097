.container-small-profile{
    width: 40%;
    background-color: #FFFFFF;
    margin: 0 auto;
}
.profile-heading{
    font-size: 1.4rem;
    font-weight: 600;
    padding: 3%;
}
.logout-btn{
    position: absolute;
    right: 0px;
    /* left: 1500px; */
    top: 17%;
}
