
.card .card-title {
    font-size: 1.4rem;
    font-weight: 300;
}

#test1 .card .card-content p {
    margin: 0;
    font-size:.9rem;
   
    color:black!important;
    
  
 
}

#test1 .card .card-content{
   padding:12px 12px 0px 12px!important; 
 
}
#test1 .card .card-action{
   border-top:0px!important;
   padding:4px 0px!important;
 
}
#test1 .card .card-action a{
   font-size: 1rem!important;
  
 }

#test1 .card .card-content p {
    margin: 0;
    font-family: 'Rubik', sans-serif;
    color:#333;
}
#test1 .card .card-content p:hover {
    margin: 0;
   
 
    color:black!important;
}
.card .card-content a{
    color:#ffffff!important;
    
}
#test1 .card .card-content a{
    color:#00c853!important;
    
}
#test1 .card .material-icons {
    font-size: 1rem!important;
    color:#00c853!important;
}
#test1 .card .card-content .card-title {
    margin-bottom: 0px!important;
}

#test1 .card .card-title {
    font-size: 1.6rem;
    font-weight: 300;
}

#test1 .card .card-action a:not(.btn):not(.btn-large):not(.btn-large):not(.btn-floating){
    color: #333;
    padding:10px 25px;
    border-radius:5px;
    margin-right: 0px!important;
    font-weight: bold!important;
} 

#test1 .card .card-action a:not(.btn):not(.btn-large):not(.btn-large):not(.btn-floating):hover {
    color: #ffffff;
    background-color: #f44336;
    padding:10px 25px;
    border-radius:5px;
    margin-bottom: 50px!important;
}
#test1 .portion-custom{
    position: absolute;
    z-index: 1;
    right:5%;
    bottom:20%;
}
.price{
    font-weight: bold;
    color: #000000;
}
#test1 .card .card-image .card-title {
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding: 10px;

}

/* **************daily meal another section**************** */
#test2 .card .card-content {
    padding: 10px 14px 4px 14px;
    border-radius: 0 0 2px 2px;
    font-family: 'Poiret One', cursive;
    color:#333;
    font-weight: bold;

    
}
#test2 .card .card-content p {
    font-size: .9rem;

}

#test2 .card .card-content a {
    font-size: 1rem;

    

}
#test2 .card .card-content .starred p i{
    font-size:1rem;
    color:#ffb300;
}

.pricencart{
    background-color: #00c853;
    
}
#test2 .card .card-action{
    padding:4px 14px!important;
}
#test2 .card .card-action a:not(.btn):not(.btn-large):not(.btn-large):not(.btn-floating) {
    color: #00c853;
    font-size: .9rem;
}
.card-action-class a{
    font-size: .9rem;
    font-weight: bold;
}

.card-action-class {
    padding: 6px 14px;
}

.card .card-action {
    position: relative;
    background-color: inherit;
    border-top: 1px solid rgba(160,160,160,0.2);
    padding: 6px 14px;
}
.cart-product-name{
    font-family: monospace;
}