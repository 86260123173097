.space-custom{
    width: 100%;
    height: 2%;
    padding-top: 3%;
}
.font-1rem{
    font-size: 1rem;
}
.padding-left-3{
    padding-left: 3%;
}
.space-custom-fullcategory{

    width: 100%;
    height: 2%;
    padding-top: 1.5%
}
.inside-address{
    padding-left:2%;
}

.custom-wide-button-small{
    border: 1px solid black;
    height: 35px;
    width: 40%;
    border-width: thin;
    border-radius:7px;
    box-sizing: border-box;
    border-color: rgb(197, 190, 190);

}
.custom-wide-button-small:hover{
    border: 1px solid black;
    height: 35px;
    width: 40%;
    border-width: thin;
    border-radius:7px;
    box-sizing: border-box;
    border-color: rgb(152, 152, 152);
    background-color: #e8eaf6;
}
.button-image-container-right{
    position: relative;
    left: 70%;
    top: 5%;
}
.button-image-right{
    width: 15%;
}
.custom-button-text-small{
    position: absolute;
    padding-left: 10px;
    padding-top: 2px;
    color: #949392;
    font-weight: 500;
}
svg:not(:root) {
    overflow: hidden;
    display: none;
}
.clickable{
    cursor: pointer;
}
.padding-bottom-1{
    padding-bottom: .6vw;
}